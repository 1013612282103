import React from 'react';
import '../styles/Footer.scss';

const Footer = () => {
  return (
      <div className='footer'>
        © Rachel Wong | 2021
      </div>
  );
}

export default Footer;